import { opBillingConstants } from "../constants";
import { alertActions } from "./alert.actions";
import { opBillingService } from "../services";
import { history } from "../helpers/history";
import { toast, Bounce } from "react-toastify";
import { da } from "date-fns/locale";
import moment from "moment";
import "moment-timezone";

export const opBillingActions = {
  addBill,
  addHold,
  getBillByUHID,
  getAllHold,
  getAllByDateRange,
  getBillStatus,
  addAdvanceBill,
  clearHold,
  getBillQueue,
  selectBill,
  clearBill,
  cancelBillByBillNo,
  getReceivableByUHID,
  getSearchBillResult,
  refundBillByBillno,
  makepartialpayment,
  getAll,
  oldBillBody,
};

const mapStatus = {
  1: "fully paid",
  2: "hold",
  3: "advance",
  4: "cancel",
  5: "refund",
  6: "receivable",
};

function utcToIst(data) {
  if (data) {
    return moment
      .utc(data)
      .tz("Asia/Kolkata")
      .format("DD/MM/YYYY hh:mm:ss a");
  } else {
    return "-";
  }
}

const calculateNetAmount = (amount, discountPercentage, gst) => {
  const discount = discountPercentage
    ? parseFloat(discountPercentage) / 100
    : 0;
  let discountedAmount = parseFloat(amount) * (1 - discount);

  let netAmount = discountedAmount * (1 + (parseFloat(gst) / 100 || 0));

  return Math.round(netAmount);
};

const mapGroupService = (service, data) => ({
  serviceType: 1,
  quantity: service.Qty || 1,
  sequence: parseInt(service.SEQUENCE) || 0,
  serviceAmount: parseFloat(service.GROUP_AMT),
  netAmount: calculateNetAmount(
    service.GROUP_AMT,
    data.approved_discount?.discount_percentage,
    0
  ),
  serviceName: service.GROUP_NAME,
  serviceTypeId: service.id || 0,
  departmentId: service.D_CODE || 0,
  gstPercent: parseFloat(service.gstAmount) || 0,
  gstAmount: parseFloat(service.TAX_AMOUNT_OP) || 0,
  consultantDiscount: 0,
  consultantShareAmount: 0,
  hospitalShareAmount: 0,
  hospitalShareDiscount: 0,
  totalDiscount: 0,
  totalAmount: 0,
});

const mapPackageService = (service, data) => ({
  serviceType: 2,
  quantity: service.Qty || 1,
  sequence: parseInt(service.SEQUENCE) || 0,
  serviceAmount: parseFloat(service.PACKAGE_AMT),
  netAmount: calculateNetAmount(
    service.PACKAGE_AMT,
    data.approved_discount?.discount_percentage,
    0
  ),
  serviceName: service.PACKAGE_NAME,
  serviceTypeId: service.id || 0,
  departmentId: service.D_CODE || 0,
  gstPercent: parseFloat(service.gstAmount) || 0,
  gstAmount: parseFloat(service.TAX_AMOUNT_OP) || 0,
  consultantDiscount: 0,
  consultantShareAmount: 0,
  hospitalShareAmount: 0,
  hospitalShareDiscount: 0,
  totalDiscount: 0,
  totalAmount: 0,
});

const mapOtherService = (service, data) => ({
  serviceType: 3,
  quantity: service.Qty || 1,
  sequence: parseInt(service.SEQUENCE) || 0,
  serviceAmount: parseFloat(service.SERVICE_AMT),
  netAmount: calculateNetAmount(
    service.SERVICE_AMT,
    data.approved_discount?.discount_percentage,
    service.TAX_AMOUNT_OP
  ),
  serviceName: service.SERVICE_NAME,
  serviceTypeId: service.id || 0,
  departmentId: service.D_CODE || 0,
  gstPercent: parseFloat(service.TAX_AMOUNT_OP) || 0,
  gstAmount: parseFloat(service.gstAmount) || 0,
  consultantDiscount: 0,
  consultantShareAmount: 0,
  hospitalShareAmount: 0,
  hospitalShareDiscount: 0,
  totalDiscount: 0,
  totalAmount: 0,
});

function newBillingRequest(data) {
  let services1 = [];
  let services2 = [];
  let payment_detail = {
    approvedAmount: 0,
    consumedAmount: 0,
    selfPay: 0,
  };

  if (data.bill_type !== "Advance") {
    if (data.registration_fees) {
      services1 = data?.registration_fees?.map((service) => ({
        serviceType: 3,
        quantity: service.Qty || 1,
        sequence: parseInt(service.SEQUENCE) || 0,
        serviceAmount: service.op_registration_fees || 0,
        netAmount: Math.round(
          (service.op_registration_fees || 0) *
            (1 -
              (data.approved_discount
                ? parseFloat(data.approved_discount.discount_percentage) / 100
                : 0))
        ),
        serviceName: `Registration Charge (${service.rtm_reg_type})`,
        serviceTypeId: 0,
        departmentId: service.D_CODE || 0,
      }));
    }
    if (data.doctor_fees?.type) {
      let service = data.doctor_fees;
      services1.push({
        serviceType: 3,
        quantity: service.Qty || 1,
        sequence: parseInt(service.SEQUENCE) || 0,
        serviceAmount: service.amount || 0,
        netAmount: Math.round(
          (service.amount || 0) *
            (1 -
              (data.approved_discount
                ? parseFloat(data.approved_discount.discount_percentage) / 100
                : 0))
        ),
        serviceName: service.type,
        serviceTypeId: 0,
        departmentId: service.D_CODE || 0,
      });
    }
  }

  services2 = data.serviceList.map((service) => {
    switch (service.type) {
      case "groups":
        return mapGroupService(service, data);
      case "packages":
        return mapPackageService(service, data);
      default:
        return mapOtherService(service, data);
    }
  });
  let advanceConsumed = 0;
  if (data.bill_summary.advance_amount) {
    if (data.bill_summary.totalAmount < data.bill_summary.advance_amount) {
      advanceConsumed = data.bill_summary.totalAmount;
    } else {
      advanceConsumed = data.bill_summary.advance_amount;
    }
  }

  if (data.payment_type === "corporate") {
    payment_detail.approvedAmount = data.corporate.corporate_approved_amount;
    payment_detail.consumedAmount = data.corporate.consumed_amount;
    payment_detail.selfPay = data.corporate.selfPay;
  } else if (data.payment_type === "insurance") {
    payment_detail.approvedAmount = data.insurance.tpa_approved_amount;
    payment_detail.consumedAmount = data.insurance.consumed_amount;
    payment_detail.selfPay = data.insurance.selfPay;
  }

  return {
    opBillingRequest: {
      consultantFee: data.doctor_fees?.amount || 0,
      consultantId: data.doctor_fees?.id ? parseInt(data.doctor_fees.id) : null,
      billType:
        data.bill_type === "Normal" ? 1 : data.bill_type === "Advance" ? 3 : 0,
      registrationFeeId: data?.registration_fees
        ? data.registration_fees[0]?.id
          ? data.registration_fees[0]?.id
          : null
        : null,

      services: [...services1, ...services2],
      dueAmount: parseFloat(
        data.bill_summary.totalPayabal -
          (data.bill_summary.consume_advance_amount || 0) -
          ((parseFloat(data.cash_amount) || 0) +
            (parseFloat(data.cheque_amount) || 0) +
            (parseFloat(data.digital_amount) || 0) +
            (parseFloat(data.card_amount) || 0)) -
          (data.shortfallAmount || 0)
      ),
      discount: {
        totalDiscount: data.discount_amount || 0,
        discountPercentage: data.approved_discount?.discount_percentage || 0,
        discountName: data.approved_discount?.discount_name || "",
        discountComment: data.approved_discount?.discount_comment || "",
        discountApprovedBy: data.approved_discount?.discount_approved_by || "",
        discountAmount: data.discount_amount || 0,
        isDiscountApproved: data.approved_discount_status,
        discountNotApplicableAmount: 0,
      },
      billSummary: {
        totalAmount: parseFloat(data.total_bill_amount || 0),
        totalPayable: data.bill_summary.totalAmount - advanceConsumed || 0,
        advanceAmount: data.bill_summary.advance_amount || 0,
        totalAdvanceAmount: data.total_advance || 0,
        consumeAdvanceAmount: advanceConsumed,
        totalConsumeAdvanceAmount: 0,
        netAmount: parseFloat(data.bill_summary.totalAmount) || 0,
      },
      cashPayment: {
        chequeDate: data.cash_payment.cheque_date
          ? data.cash_payment.cheque_date
          : null,

        transactionId: data.transaction_id ?? "",
        cardType:
          data.card_type === "Credit" ? 1 : data.card_type === "Debit" ? 2 : 0,
        lastFourDigits: data.cash_payment?.card_number ?? 0,
        chequeNo: data.cash_payment.check_no ?? "",
        cashAmount: parseFloat(data.cash_amount) || 0,
        chequeAmount: parseFloat(data.cheque_amount) || 0,
        digitalAmount: parseFloat(data.digital_amount) || 0,
        cardAmount: parseFloat(data.card_amount) || 0,
      },
      paymentDetail: payment_detail,
      patientId: data.patient_id,
      paymentType:
        data.payment_type === "cash"
          ? 1
          : data.payment_type === "insurance"
          ? 2
          : data.payment_type === "corporate"
          ? 3
          : 1,
      tax: data.tax || 0,
      referralName: data.all_referal_name || "",
      sourceOfPayment: data.source_of_payment ? data.source_of_payment : "",
      advanceAmountComment: data.advance_amount_comment
        ? data.advance_amount_comment
        : "",
      balanceAmount: parseFloat(
        data.bill_summary.totalPayabal -
          (data.bill_summary.consume_advance_amount || 0) -
          ((parseFloat(data.cash_amount) || 0) +
            (parseFloat(data.cheque_amount) || 0) +
            (parseFloat(data.digital_amount) || 0) +
            (parseFloat(data.card_amount) || 0)) -
          (data.shortfallAmount || 0)
      ),
      shortfallAmount: data.shortfall_amount || 0,
      reasonForDue: data.reason_for_due || "",
      nonCancellationReason: "",
    },
  };
}

function oldBillBody(apiResponse) {
  const totalCashAmount = apiResponse.receipts.reduce(
    (sum, receipt) =>
      receipt.receiptType !== 4 && receipt.receiptType !== 5
        ? sum + (receipt.cashAmount || 0)
        : sum,
    0
  );

  const totalDigitalAmount = apiResponse.receipts.reduce(
    (sum, receipt) =>
      receipt.receiptType !== 4 && receipt.receiptType !== 5
        ? sum + (receipt.digitalAmount || 0)
        : sum,
    0
  );

  const totalChequeAmount = apiResponse.receipts.reduce(
    (sum, receipt) =>
      receipt.receiptType !== 4 && receipt.receiptType !== 5
        ? sum + (receipt.chequeAmount || 0)
        : sum,
    0
  );

  const totalCardAmount = apiResponse.receipts.reduce(
    (sum, receipt) =>
      receipt.receiptType !== 4 && receipt.receiptType !== 5
        ? sum + (receipt.cardAmount || 0)
        : sum,
    0
  );
  // only add if receivable or normal
  const totalAmountTable = apiResponse.services.reduce(
    (sum, service) => sum + (service?.netAmount || 0),
    0
  );

  return {
    id: apiResponse.id,

    bill_no: apiResponse.billNumber,
    approved_discount: {
      total_discount: apiResponse.discount?.totalDiscount || "",
      discount_percentage: apiResponse.discount?.discountPercentage || 0,
      discount_name: apiResponse.discount?.discountName || "",
      discount_comment: apiResponse.discount?.discountComment || "",
      discount_approved_by: apiResponse.discount?.discountApprovedBy || "",
      discount_amount: apiResponse.discount?.discountAmount || 0,
      is_discount_approved: apiResponse.discount?.isDiscountApproved
        ? true
        : false,
    },
    bill_summary: {
      total_amount: apiResponse.billSummary?.totalAmount || 0,
      total_payable: apiResponse.billSummary?.totalPayable || 0,
      advance_amount: apiResponse.billSummary?.advanceAmount || 0,
      total_advance_amount: apiResponse.billSummary?.totalAdvanceAmount || 0,
      consume_advance_amount:
        apiResponse.billSummary?.consumeAdvanceAmount || 0,
      total_consume_advance_amount:
        apiResponse.billSummary?.totalConsumeAdvanceAmount || 0,
      net_amount: apiResponse.billSummary?.netAmount || 0,
    },
    cash_payment: {
      cash_value: totalCashAmount || 0,
      digital_amount: totalDigitalAmount || 0,
      paid_amount:
        totalCashAmount +
          totalCardAmount +
          totalChequeAmount +
          totalDigitalAmount ?? 0,
      balance_amount: apiResponse.dueAmount || 0,
    },
    recipt_no: apiResponse.receipts.map((object) => ({
      receipt_type: object?.receiptType || 0,
      receipt_no: object?.receiptNumber || "",
      op_billing_id: object?.opBillingId || 0,
      patient_id: object?.patientId || 0,
      amount: object?.amount || 0,
      cash_amount: object?.cashAmount || 0,
      cheque_amount: object?.chequeAmount || 0,
      digital_amount: object?.digitalAmount || 0,
      card_amount: object?.cardAmount || 0,
      card_type: object?.cardType || 0,
      cheque_no: object?.chequeNo || "",
      cheque_date: object?.chequeDate || "",
      transaction_id: object?.transactionId || "",
      card_last_four_digits: object?.cardLastFourDigits || "",
      payment_type: object?.paymentType || 0,
      approved_amount: object?.approvedAmount || 0,
      consumed_amount: object?.consumedAmount || 0,
      self_pay: object?.selfPay || 0,
      receipt_date: utcToIst(object.createdDate),
    })),
    doctor_fees: [
      {
        type: apiResponse.consultantId,
        amount: apiResponse.consultantFee,
      },
    ],
    registration_fees: [
      {
        id: apiResponse.registrationFeeId,
      },
    ],
    serviceList: apiResponse.services.map((service) => {
      if (service.serviceType === 3)
        return {
          SERVICE_NAME: service.serviceName,
          Qty: service.quantity,
          SEQUENCE: service.sequence,
          SERVICE_AMT: service.serviceAmount,
          D_CODE: service.departmentId,
          USERDEFINED_NAME: service.serviceName,
          netAmount: service.netAmount,
          totalAmount: service.netAmount,
          type: "services",
        };
      else if (service.serviceType === 2)
        return {
          PACKAGE_NAME: service.serviceName,
          Qty: service.quantity,
          SEQUENCE: service.sequence,
          PACKAGE_AMT: service.serviceAmount,
          D_CODE: service.departmentId,
          USERDEFINED_NAME: service.serviceName,
          netAmount: service.netAmount,
          totalAmount: service.netAmount,
          type: "packages",
        };
      else
        return {
          GROUP_NAME: service.serviceName,
          Qty: service.quantity,
          SEQUENCE: service.sequence,
          GROUP_AMT: service.serviceAmount,
          D_CODE: service.departmentId,
          USERDEFINED_NAME: service.serviceName,
          netAmount: service.netAmount,
          totalAmount: service.netAmount,
          type: "groups",
        };
    }),
    approved_discount_status: apiResponse.discount?.discountPercentage
      ? true
      : false,
    isFullyPaid: apiResponse.dueAmount ? false : true,
    due_amount: apiResponse.dueAmount || 0,
    tax: apiResponse.tax,
    shortfall_amount: apiResponse.shortfallAmount,
    payment_type: apiResponse.paymentType === 1,
    reason_for_due: apiResponse.reasonForDue,
    bill_type: apiResponse.billType,
    cash_amount: totalCashAmount,
    digital_amount: totalDigitalAmount,
    cheque_amount: totalChequeAmount,
    card_amount: totalCardAmount,
    discount_amount: apiResponse.discount.totalDiscount || 0,
    total_amount: apiResponse.billSummary?.totalAmount || 0,
    card_type: apiResponse.receipts[0]?.cardType,
    // net_amount:
    //   apiResponse.billSummary?.totalAmount -
    //   (apiResponse.discount?.totalDiscount || 0),
    patient_id: {
      firstname: apiResponse.patientBasicInfo.fullName || "",
      middlename: "",
      lastname:  "",
      partner_uhid: apiResponse.patientBasicInfo.partnerUhid || 0,
      reg_date: apiResponse.patientBasicInfo.registrationDate || "",
      gender_code: apiResponse.patientBasicInfo.gender || 0,
      payment_type: apiResponse.patientBasicInfo.patientType || 0,
      email: apiResponse.patientBasicInfo.email || "",
      referral_doctor: apiResponse.patientBasicInfo.referralDoctor || 0,
      referral_phone: apiResponse.patientBasicInfo.referralPhone || "",
      age_in_months: apiResponse.patientBasicInfo.ageInMonth || 0,
      age_in_years: apiResponse.patientBasicInfo.ageInYear || 0,
      age_in_days: apiResponse.patientBasicInfo.ageInDays || 0,
      mobile: apiResponse.patientBasicInfo.phoneNumber || "",
      id: apiResponse.patientBasicInfo.id || 0,
      patient_uhid: apiResponse.patientBasicInfo.uhid || "",
    },
    patient_uhid: apiResponse.patientBasicInfo.uhid || "",
    isRefunded: apiResponse.status === 5 ? true : false,
    isCanceled: apiResponse.status === 4 ? true : false,
    status: mapStatus[apiResponse.status],
    created_on: utcToIst(apiResponse.createdDate),

    consultant: apiResponse.consultant,
    registrationFee: apiResponse.registrationFee,
    consultantId: apiResponse.consultantId,
    referralDoctor: apiResponse.referralName,
    refunded_amt: apiResponse.refundAmount,
    cancelled_on: utcToIst(apiResponse.cancellationDate),
    cancellation_approved_by: apiResponse.cancellationApprovedBy,
    cancelation_id: apiResponse.cancellationId,
    created_by: apiResponse.createdBy,
  };
}

function refundBillByBillno(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.refundBillByBillno(payload).then(
      (result) => {
        if (result.isSuccess) {
          //  dispatch(success(payload));
          toast("refunded successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.REFUND_OPD_BILLBYBILLNO_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.REFUND_OPD_BILLBYBILLNO_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.REFUND_OPD_BILLBYBILLNO_FAILURE, error };
  }
}

function getSearchBillResult(payload, billStatus) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getSearchBillResult(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_REQUEST,
      };
    } else {
      return { type: opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_REQUEST };
    }
  }
  function success(data) {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_SUCCESS,
        data,
      };
    } else {
      return {
        type: opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_SUCCESS,
        data,
      };
    }
  }
  function failure(error) {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_SEARCH_FAILURE,
        error,
      };
    } else {
      return {
        type: opBillingConstants.GET_OPD_BILL_SEARCH_RESULT_FAILURE,
        error,
      };
    }
  }
}

function getReceivableByUHID(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getReceivableByUHID(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.GET_OPD_RECEIVABLEBYUHID_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.GET_OPD_RECEIVABLEBYUHID_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.GET_OPD_RECEIVABLEBYUHID_FAILURE, error };
  }
}

function cancelBillByBillNo(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.cancelBillByBillNo(payload).then(
      (result) => {
        if (result.isSuccess) {
          //dispatch(success(result.value));
          toast("cancelled successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("request failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("request failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.CANCEL_BILL_BYBILLNO_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.CANCEL_BILL_BYBILLNO_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.CANCEL_BILL_BYBILLNO_FAILURE, error };
  }
}

function addBill(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.addBill(newBillingRequest(payload)).then(
      (result) => {
        if (result.isSuccess) {
          //dispatch(success(result.value));
          toast("added successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.ADD_OPD_BILLING_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.ADD_OPD_BILLING_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.ADD_OPD_BILLING_FAILURE, error };
  }
}

function addHold(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.addHold(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.ADD_OPD_BILLING_HOLD_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.ADD_OPD_BILLING_HOLD_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.ADD_OPD_BILLING_HOLD_FAILURE, error };
  }
}

function clearHold(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.clearHold(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.CLEAR_OPD_BILLING_HOLD_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.CLEAR_OPD_BILLING_HOLD_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.CLEAR_OPD_BILLING_HOLD_FAILURE, error };
  }
}

function getAllByDateRange(payload, billStatus) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getAllByDateRange(payload).then(
      (result) => {
        if (result.isSuccess) {
          const oldBody = { data: [], sumTotalAmount: 0 };
          result.value.forEach((bill) => {
            oldBody.sumTotalAmount += bill.billSummary.totalAmount;
            oldBody.data.push(oldBillBody(bill));
          });

          dispatch(success(oldBody, result.pagedInfo));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("request failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("request failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_REQUEST,
      };
    } else {
      return { type: opBillingConstants.GET_OPD_BILLBYDATERANGE_REQUEST };
    }
  }
  function success(data, pagedInfo) {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_SUCCESS,
        data,
      };
    } else {
      return {
        type: opBillingConstants.GET_OPD_BILLBYDATERANGE_SUCCESS,
        body: { data, pagedInfo },
      };
    }
  }
  function failure(error) {
    if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILL_RECEIVABLE_DATERANGE_FAILURE,
        error,
      };
    } else {
      return {
        type: opBillingConstants.GET_OPD_BILLBYDATERANGE_FAILURE,
        error,
      };
    }
  }
}

function getBillStatus(payload, billStatus) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getAllByDateRange(payload).then(
      (result) => {
        if (result.isSuccess) {
          const oldBody = { data: [], sumTotalAmount: 0 };
          result.value.forEach((bill) => {
            oldBody.sumTotalAmount += bill.billSummary.totalAmount;
            oldBody.data.push(oldBillBody(bill));
          });
          dispatch(success(oldBody, result.pagedInfo));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("request failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("request failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    if (billStatus === "cancelled") {
      return { type: opBillingConstants.GET_OPD_BILLCALCELLED_REQUEST };
    } else if (billStatus === "refunded") {
      return { type: opBillingConstants.GET_OPD_BILLREFUNDED_REQUEST };
    } else if (billStatus === "receivable") {
      return { type: opBillingConstants.GET_OPD_BILLRECEIVABLE_REQUEST };
    }
  }
  function success(data, pagedInfo) {
    if (billStatus === "cancelled") {
      return {
        type: opBillingConstants.GET_OPD_BILLCALCELLED_SUCCESS,
        body: { data, pagedInfo },
      };
    } else if (billStatus === "refunded") {
      return {
        type: opBillingConstants.GET_OPD_BILLREFUNDED_SUCCESS,
        body: { data, pagedInfo },
      };
    } else if (billStatus === "receivable") {
      return {
        type: opBillingConstants.GET_OPD_BILLRECEIVABLE_SUCCESS,
        body: { data, pagedInfo },
      };
    }
  }
  function failure(error) {
    if (billStatus === "cancelled") {
      return { type: opBillingConstants.GET_OPD_BILLCALCELLED_FAILURE, error };
    } else if (billStatus === "refunded") {
      return { type: opBillingConstants.GET_OPD_BILLREFUNDED_FAILURE, error };
    } else if (billStatus === "receivable") {
      return { type: opBillingConstants.GET_OPD_BILLRECEIVABLE_FAILURE, error };
    }
  }
}

function getBillByUHID(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getBillByUHID(payload).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.GET_OPD_BILLBYUHID_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.GET_OPD_BILLBYUHID_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.GET_OPD_BILLBYUHID_FAILURE, error };
  }
}

function getAllHold() {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getAllHold().then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_HOLD_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_HOLD_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_HOLD_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getAll().then(
      (result) => {
        if (result.success) {
          dispatch(success(result.data));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_BILL_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_BILL_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.GET_ALL_OPD_BILLING_BILL_FAILURE, error };
  }
}

function getBillQueue(query) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.getBillQueue(query).then(
      (result) => {
        if (result.success) {
          dispatch(success(result.orders));

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast(result.message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast(error.message, {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.GET_OPD_BILLING_QUEUE_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.GET_OPD_BILLING_QUEUE_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.GET_OPD_BILLING_QUEUE_FAILURE, error };
  }
}

function addAdvanceBill(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.add(newBillingRequest(payload)).then(
      (result) => {
        if (result.isSuccess) {
          dispatch(success(result.value));
          toast("advance added", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });
          history.goBack();
          dispatch(alertActions.success(result.message));
        } else {
          dispatch(failure(result.err));
          toast("request failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("request failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.ADD_OPD_BILLING_ADVANCE_REQUEST };
  }
  function success(data) {
    return { type: opBillingConstants.ADD_OPD_BILLING_ADVANCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: opBillingConstants.ADD_OPD_BILLING_ADVANCE_FAILURE, error };
  }
}

function selectBill(data) {
  return (dispatch) => {
    dispatch(success(data));
    dispatch(alertActions.success("Bill Loaded"));
  };
  function success(data) {
    return { type: opBillingConstants.SELECT_BILL_SUCCESS, data };
  }
}

function clearBill() {
  return (dispatch) => {
    dispatch(success());
    dispatch(alertActions.success("Bill Loaded"));
  };
  function success() {
    return { type: opBillingConstants.CLAER_BILL };
  }
}

function makepartialpayment(payload) {
  return (dispatch) => {
    dispatch(request());

    opBillingService.makepartialpayment(payload).then(
      (result) => {
        if (result.isSuccess) {
          // dispatch(success(result.value));
          toast("received successfully", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
          });

          dispatch(alertActions.success(result.message));
          //history.goBack();
        } else {
          dispatch(failure(result.err));
          toast("request failed", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "error",
          });
        }
      },
      (error) => {
        toast("request failed", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        });
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_REQUEST };
  }
  function success(data) {
    return {
      type: opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: opBillingConstants.PARTIALPAYMENT_OPD_BILLBYBILLNO_FAILURE,
      error,
    };
  }
}
